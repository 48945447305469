import React, { PureComponent, ReactNode } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { Document, Page } from 'react-pdf'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { WindowLocation } from '@reach/router';

import Layout from '../components/layout'
import theme from '../theme'
import ContactInfo from '../components/contactInfo'
import Section, {Header, SubHeader, Divider, SubSection} from '../components/section/section'
import Brochure from '../documents/strangeland-recording-brochure.pdf'

interface IconWrapperProps {
    shouldDisable: boolean
}

interface PdfDocumentProps extends IconWrapperProps {
    handleClick: () => void
}

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255,255,255, 0.8);
    width: 75px;
    cursor: pointer;
    pointer-events: ${(props: IconWrapperProps) => props.shouldDisable ? 'none' : 'auto'};
    opacity: ${(props: IconWrapperProps) => props.shouldDisable ? '0.5' : '1.0'};
`

const PreviousPage: React.SFC<PdfDocumentProps> = (props) => (
    <IconWrapper
        onClick={props.handleClick}
        shouldDisable={props.shouldDisable}>
        <FontAwesomeIcon icon={faArrowLeft} />
    </IconWrapper>
)

const NextPage: React.SFC<{handleClick: () => void, shouldDisable: boolean}> = (props) => (
    <IconWrapper
        onClick={props.handleClick}
        shouldDisable={props.shouldDisable}>
        <FontAwesomeIcon icon={faArrowRight} />
    </IconWrapper>
)

type AboutPageProps = {
    location: WindowLocation,
    children: ReactNode[]
}

type AboutPageState = {
    isMapToggled: boolean,
    currentPage: number,
    numberOfPages: number
}

const AboutImage = styled.img`
    object-fit: contain;
`

const ImageSection = styled.div`
    height: 100vh;
    width: 100%;
    max-height: calc(100vw * 9/16);
    margin-top: -100px;
    box-shadow: 0px 2px 10px 0px;
    position: relative;
    z-index: 1;
    overflow: hidden;

    @media(max-width: 768px) {
        margin-top: -56px;
    }
`

const ImageSectionTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  top: 120px;
  left: 40px;

  @media(max-width: 768px) {
    top: 66px;
    left: 20px
  }
`

const ImageSectionText = styled.p<{top: number}>`
  font-size: 3.5rem;
  font-weight: 500;
  color: white;
  left: 20px;
  font-family: roboto;
  margin: 0;

  @media(max-width: 768px) {
    font-size: 24px;
  }
`

const HeaderBar = styled.div`
  width: 100%;
  background-color: #313131;
  position: relative;
  z-index: 0;
`

const HeaderBarText = styled.p`
  font-size: 1.5rem;
  color: white;
  text-align: center;
  margin: 0;
  padding: 10px;

  @media(max-width: 520px) {
    font-size: 1.25rem;
  }
`

const HeaderBarBottom = styled.div`
  height: 20px;
  background-color: #232323;
`
export default class About extends PureComponent<AboutPageProps, AboutPageState> {
    constructor(props: AboutPageProps) {
        super(props)

        this.toggleFloorPlan = this.toggleFloorPlan.bind(this)
        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this)
        this.handlePreviousPage = this.handlePreviousPage.bind(this)
        this.handleNextPage = this.handleNextPage.bind(this)
        this.downloadBrochure = this.downloadBrochure.bind(this)

        this.state = {
            isMapToggled: false,
            currentPage: 1,
            numberOfPages: 0
        }
    }

    toggleFloorPlan() : void {
        this.setState({
            isMapToggled: !this.state.isMapToggled
        })
    }

    onDocumentLoadSuccess({ numPages } : { numPages: number}) : void {
        this.setState({
            currentPage: 1,
            numberOfPages: numPages
        })
    }

    handlePreviousPage() : void {
        this.setState({
            currentPage: this.state.currentPage !== 0 ? this.state.currentPage - 1 : 0
        })
    }

    handleNextPage() : void {
        console.log('Number of Pages: ', this.state.numberOfPages)
        console.log('Current Page: ', this.state.currentPage)
        this.setState({
            currentPage: this.state.currentPage !== this.state.numberOfPages ? this.state.currentPage + 1 : this.state.numberOfPages
        })
    }

    downloadBrochure() : void {
        axios({
            url: Brochure,
            method: 'GET',
            responseType: 'blob',
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'strange-land-recording-studios.pdf')
            link.click()
            window.URL.revokeObjectURL(url)
          })
    }

    render() {
        const PDFDocument = typeof window !== 'undefined' && window.innerWidth > 768 ? (
            <Section
                margin="40px auto"
                boxShadow="0px 2px 10px -3px black"
                borderRadius="2px">
                <PreviousPage handleClick={this.handlePreviousPage} shouldDisable={this.state.currentPage === 1} />
                <Document 
                    file={Brochure}
                    onLoadSuccess={this.onDocumentLoadSuccess}>
                    <Page pageNumber={this.state.currentPage} />
                </Document>
                <NextPage handleClick={this.handleNextPage} shouldDisable={this.state.currentPage === this.state.numberOfPages} />
            </Section>
        ) : null

        return(
            <Layout location={this.props.location}>
                <ImageSection>
                    <ImageSectionTextWrapper>
                        <ImageSectionText top={120}>STRANGE</ImageSectionText>
                        <ImageSectionText top={150}>LAND</ImageSectionText>
                        <ImageSectionText top={180}>RECORDING</ImageSectionText>
                        <ImageSectionText top={210}>STUDIOS</ImageSectionText>
                    </ImageSectionTextWrapper>
                    <AboutImage src={require('../images/studio-a/studio-a-mixing-board.jpg')}/>
                </ImageSection>
                <Section
                    flexDirection="column" 
                    margin="0 0 40px 0"
                    id="strange-land">
                    <Header>About Us</Header>
                    <Divider themeColor={theme.palette.primary.main}/>
                    <SubHeader>
                        Strange Land Recording Studios features two world-class
                        recording studios each designed by top-studio
                        designer Ross Alexander. Both studios are
                        beautifully adorned with custom wood and
                        stone accents. Each studio features a private
                        lounge, kitchen, and restroom.
                    </SubHeader>
                </Section>
                <Section 
                    width="100%" 
                    margin="0"
                    background={theme.palette.primary.main}
                    flexDirection="column">
                    <SubSection>
                        <ContactInfo margin="0" color="white"/>
                    </SubSection>
                </Section>
                <Section id="brochure" height="0" margin="0"/>
                <Section
                    margin="0"
                    padding="40px 0"
                    flexDirection="column"
                    width="100%"
                    background="whitesmoke">
                    <Section 
                        flexDirection="column" 
                        margin="0">
                        <Header>Brochure</Header>
                        <Divider themeColor={theme.palette.primary.main}/>
                    </Section>
                    {PDFDocument}
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <FontAwesomeIcon icon={faFileDownload}/>
                        <p style={{margin: '0 0 0 20px', cursor: 'pointer', color: theme.palette.primary.light}} onClick={this.downloadBrochure}>Download</p>
                    </div>
                </Section>
            </Layout>
        )
    }
}